(function ($) {
  let answers = []
  let questionsLength = 0
  let showPasswordTime = 3000

  $(document).ready(function () {
    // console.log('-- reportUrl --')
    // console.log(reportUrl)
    let questions = $('[data-question]')
    let progressBar = $('[data-progress-bar]')
    let itemsPerPage = 8
    let currentPage = 1
    let pageCount = Math.ceil(questions.length / itemsPerPage)
    let nextPageButton = $('[data-next-page]')
    let bottomUrls = $('[data-bottom-urls]')
    questionsLength = questions.length

    paginateQuestions ()
    listenEvents()

    // Result page
    if ($('#checkup-subscribe').length) {
      bottomUrls.removeClass('d-flex').addClass('d-none')
    }
    $('[data-cancel-button]').click(function () {
      console.log('data-cancel-button click!')
      if (reportUrl.length > 0) {
        $(location).attr('href', reportUrl)
      }
      // $('#checkup-subscribe').fadeOut()
      bottomUrls.removeClass('d-none').addClass('d-flex')
    })
    $('[data-subscribe]').click(function (e) {
      let subscribeButton = $(e.currentTarget)
      showSpiner(subscribeButton)

      $.get({
        url: subscribeUrl,
        data: {
          productType: 'discount50',
          redirectPage: 'check_up_results'
        }
      })
        .done(function (response) {
          hideSpinner(subscribeButton)
          $(location).attr('href', response.checkoutUrl)
        })
        .fail(function () {
          showServerError('Something went wrong')
          hideSpinner(subscribeButton)
        })
    })
    // End Result page

    // Slider check up
    $('[data-checkup-slider]').slick({
      infinite: true,
      centerMode: true,
      dots: false,
      speed: 1000,
      centerPadding: '0',
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      slidesToShow: 1,
      fade: true,
      cssEase: 'linear',
      pauseOnFocus: false,
      pauseOnHover: false
    })
    // End slider check up

    // Slider check up intro
    $('[data-checkup-intro-slider]').slick({
      infinite: true,
      centerMode: true,
      dots: true,
      centerPadding: '40px',
      autoplay: true,
      arrows: false,
      slidesToShow: 1
    })
    // End slider check up intro

    $('[data-option-value]').click(function (e) {
      let answerIsset = false
      let current = $(e.currentTarget)
      let currentQuestionId = current.closest('.question-block').data('question')
      let currentValue = current.data('option-value')

      current.closest('.answer').find('.option').removeClass('checked')
      current.closest('.question-block').find('.question-stub').html(current.data('option-title'))
      current.addClass('checked')

      answers.forEach(function (item, index, array) {
        if (item.questionId === currentQuestionId) {
          item.value = currentValue
          answerIsset = true
        }
      })
      if (answerIsset === false) {
        answers.unshift(new Answer(currentQuestionId, currentValue))
        calculateProgress()
      }
      // Scroll to next question
      let nextQuestionBlock = current.closest('.question-block').next()
      if (nextQuestionBlock.length > 0) {
        // scrollToNextQuestion(nextQuestionBlock)
        scrollToNextQuestion(current.closest('.question-block'), nextQuestionBlock)
        activateNextPageButton()
      }

      showSignUpContainer()
    })
    nextPageButton.click(function (element) {
      element.preventDefault()
      let questionContainer = $('.question-container')
      if (answers.length !== currentPage * itemsPerPage) {
        nextPageButton.removeClass('is-active')
        return
      }
      ++currentPage
      paginateQuestions()
      if (currentPage === pageCount) {
        // remove 'Next' button on last page
        $(element.currentTarget).closest('.button-container').remove()
        questionContainer.css('margin-bottom', '46px')
      }
      $('html,body').animate({
        scrollTop: questionContainer.offset().top - ($('.progress').height() * 2)
      }, 300);
      deactivateNextPageButton()
    })
    function paginateQuestions () {
      // Paginate questions
      let questions = $('.question-block')
      let firstItem = (itemsPerPage * currentPage) - itemsPerPage
      let lastItem = itemsPerPage * currentPage
      questions.each(function (index, element) {
        index = ++index
        $(element).attr('data-item', index)

        if (index <= firstItem || index > lastItem) {
          $(element).addClass('d-none')
        } else {
          $(element).removeClass('d-none')
        }
      })
    }

    function calculateProgress () {
      let progress = Math.round((answers.length / questions.length) * 100)
      progressBar.css('width', progress + '%')
      progressBar.closest('.progress').find('.percent').html(progress)
    }

    function activateNextPageButton () {
      if (answers.length === currentPage * itemsPerPage) {
        nextPageButton.addClass('is-active')
        $('html,body').animate({
          scrollTop: nextPageButton.offset().top - $(window).height()/2
        }, 300);
      }
    }

    function deactivateNextPageButton () {
      nextPageButton.removeClass('is-active')
    }

    function showSignUpContainer () {
      if (questions.length === answers.length) {
        let wellDone = $('[data-well-done]')
        wellDone.show(500)
        $('html,body').animate({
          scrollTop: wellDone.offset().top - ($('.progress').height() * 2)
        }, 300);
        fbq('track', 'finishCheckUp')
      }
    }

    // Facebook registration
    $('#fb-login').on('click', function (event) {
      event.preventDefault()
      FB.login(function (response) {
        if (response.status === 'connected') {
          facebookLogin(response)
        }
      }, {
        scope: 'public_profile,email',
        return_scopes: true
      })
    })

    function facebookLogin (response) {
      if (questionsLength === 0 || (questionsLength > 0 && questionsLength !== answers.length)) {
        showServerError('Something went wrong')
        return
      }
      $.post({
        url: facebookLoginUrl,
        type: 'POST',
        dataType: 'json',
        data: {
          facebookAccessToken: response.authResponse.accessToken,
          inviteId: $.urlParam('invite_id'),
          answers: JSON.stringify(answers)
        },
      })
        .done(function (resp) {
          console.log('Facebook login done')
          if (resp.isPaidUser) {
            console.log('User is paid')
            // if user is paid - redirecting to congratulationPageUrl
            if (congratulationPageUrl.length > 0) {
              console.log('congratulationPageUrl')
              console.log(congratulationPageUrl)
              $(location).attr('href', congratulationPageUrl)
              return
            }
          }
          console.log('Free user')
          redirectToResultsPage()
        })
        .fail(function (response) {
          console.log('Facebook login fail')
          showServerError(response.responseText)
        })
    }
    // End facebook registration
    // End of $(document).ready
  })
  function Answer (questionId, value) {
    this.questionId = questionId
    this.value = value
  }
  function scrollToNextQuestion (element, nextElement) {
    let windowCenter = $(window).height()/2
    let windowScrolledCenter = windowCenter + $(window).scrollTop()
    let topElementPosition = element.offset().top
    let topNextElementPosition = nextElement.offset().top

    if (windowScrolledCenter < topNextElementPosition) {
      if ($(window).height() < 900) {
        $('html,body').animate({
          scrollTop: topElementPosition - 50
        }, 300);
      } else {
        $('html,body').animate({
          scrollTop: topNextElementPosition - $(window).height()/2
        }, 300);
      }
    }
  }

  // Working with authorization
  function listenEvents () {
    // Listening form events
    listenInput()
    listenShowPassword()
    listenCloseButton()
    listenFormSubmit()
  }
  function showSpiner (element) {
    element.html('<i class="fa fa-spinner fa-spin fa-2x fa-fw"></i><span class="sr-only">Loading...</span>')
  }
  function hideSpinner (element) {
    element.html('Continue')
  }
  function listenInput () {
    let inputField = $('[data-validation-type]')
    inputField.unbind()

    inputField.on('keyup', function (e) {
      validateCurrentInput($(e.currentTarget))
    })
  }
  function listenCloseButton () {
    let closeButton = $('[data-close-button]')
    closeButton.unbind()
    closeButton.click(function (item) {
      $(item.currentTarget).parent().addClass('d-none')
    })
  }
  function listenFormSubmit () {
    let submitButton = $('[data-form-submit]')
    submitButton.unbind()
    // Form submit
    submitButton.on('click', function (e) {
      e.preventDefault()
      showSpiner(submitButton)
      let form = $(e.currentTarget).closest('form')

      if (validateForm(form)) {
        submitForm(form)
      } else {
        form.find('[data-validation]').each(function (index, input) {
          validateCurrentInput($(input))
        })
        hideSpinner(submitButton)
      }
    })
    // End form submit
  }
  function validateForm (form) {
    let isFormValid = true
    let validationFields = form.find('[data-validation]')

    validationFields.each(function (index, item) {
      if ($(item).attr('data-validation') !== 'true') {
        isFormValid = false
      }
    })

    if (isFormValid) {
      form.find('[data-form-submit]').addClass('is-active')
      return true
    }
    return false
  }
  function submitForm (form) {
    if (questionsLength === 0 || (questionsLength > 0 && questionsLength !== answers.length)) {
      showServerError('Something went wrong')
      hideSpinner(form.find('[data-form-submit]'))
      return
    }
    $.post({
      url: form.get(0).action,
      data: form.serialize() + '&answers=' + JSON.stringify(answers)
    })
      .done(function (response) {
        console.log('Submitting form is done!')
        let serverError = $(response).filter('[data-server-error]').html()
        if (serverError) {
          showServerError(serverError)
          clearFormData(form, 'password')
          validateForm(form)
          listenEvents()
          hideSpinner(form.find('[data-form-submit]'))
          return
        }
        let responseForm = $(response).find('[data-form="' + form.data('form') + '"]')
        if (responseForm.closest('div').hasClass('not-valid')) {
          // if form not valid
          form.replaceWith(responseForm)
          validateForm(form)
          listenEvents()
          hideSpinner(form.find('[data-form-submit]'))
          return
        }
        let isUserPaid = $(response).filter('[data-user-paid]').data('user-paid')
        if (isUserPaid) {
          console.log('User is paid')
          // if user is paid - redirecting to congratulationPageUrl
          if (congratulationPageUrl.length > 0) {
            console.log('congratulationPageUrl')
            console.log(congratulationPageUrl)
            $(location).attr('href', congratulationPageUrl)
            return
          }
        }
        console.log('Free user')
        redirectToResultsPage()
        hideSpinner(form.find('[data-form-submit]'))
        // clearFormData(form, 'password') // Hide clearing data because redirecting is slow
      })
      .fail(function () {
        hideSpinner(form.find('[data-form-submit]'))
        showServerError('Something went wrong')
        listenEvents()
      })
  }
  function redirectToResultsPage () {
    fbq('track', 'CompleteRegistration')
    console.log('Redirecting to resultsPageUrl')
    $(location).attr('href', resultsPageUrl + "?report=1")
  }
  function showServerError (errorMessage) {
    $('body').append('<div class="server-error"><div class="msg">' + errorMessage + '</div><div data-close-button class="close">Ok</div></div>')
    listenCloseButton()
  }
  function listenShowPassword () {
    let showPasswordButton = $('[data-show-eye]')
    showPasswordButton.unbind()
    showPasswordButton.click(function (e) {
      let current = $(e.currentTarget)
      let input = current.closest('.password-input').find('input')
      if (input.val().length === 0) {
        return
      }
      if (input.attr('type') === 'password') {
        input.attr('type', 'text')
        current.removeClass('hide-eye')
        current.addClass('show-eye')
        hidePassword(current)
      } else {
        input.attr('type', 'password')
        current.removeClass('show-eye')
        current.addClass('hide-eye')
      }
    })
  }
  function hidePassword (buttonElement) {
    setTimeout(function () {
      let input = buttonElement.closest('.password-input').find('input')
      if (input.attr('type') !== 'password') {
        input.attr('type', 'password')
        buttonElement.removeClass('show-eye')
        buttonElement.addClass('hide-eye')
      }
    }, showPasswordTime)
  }
  function clearFormData (form, inputType = null) {
    let input
    if (inputType) {
      input = form.find('input[type="' + inputType + '"]')
    } else {
      input = form.find('input')
    }
    input.val('')
    validateCurrentInput(input)
  }
  function validateCurrentInput (element) {
    let inputValue = element.val()
    if (inputValue.length === 0) {
      showError(element)
      validateForm(element.closest('form'))
      return
    }
    hideError(element)

    switch (element.data('validation-type')) {
      case 'email':
        if (!validateEmail(inputValue)) {
          showError(element, 'Email is invalid')
        }
        break
      case 'first-name':
        if (!validateName(inputValue)) {
          showError(element, 'First name is invalid')
        }
        break
      case 'last-name':
        if (!validateName(inputValue)) {
          showError(element, 'Last name is invalid')
        }
        break
      case 'password':
        if (!validatePassword(inputValue)) {
          showError(element, 'Passwords must be at least 6 characters')
        }
        break
      case 'first-password':
        if (!validatePassword(inputValue)) {
          showError(element, 'Passwords must be at least 6 characters')
          break
        }
        let secondPassword = $('[data-validation-type="second-password"]')
        if (secondPassword.val().length === 0) {
          break
        }
        if (inputValue !== secondPassword.val()) {
          showError(element, 'The password fields must match')
          showError(secondPassword, 'The password fields must match')
        } else {
          hideError(secondPassword)
        }
        break
      case 'second-password':
        if (!validatePassword(inputValue)) {
          showError(element, 'Passwords must be at least 6 characters')
          break
        }
        let firstPassword = $('[data-validation-type="first-password"]')
        if (inputValue !== firstPassword.val()) {
          showError(element, 'The password fields must match')
          showError(firstPassword, 'The password fields must match')
        } else {
          hideError(firstPassword)
        }
        break
    }
    validateForm(element.closest('form'))
  }
  function validatePassword (value) {
    if (value.length < 6) {
      return false
    }
    return true
  }
  function validateName (value) {
    return new RegExp(/^.{1,45}$/i).test(value)
  }
  function validateEmail (value) {
    return new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(value)
  }
  function showError (element, message = null) {
    hideError(element)
    let errorBlock = $('<span data-error class="forms-row-error"></span>')
    errorBlock.html((message === null) ? 'This field is required' : message)

    element.closest('div').append(errorBlock)
    element.addClass('is-error')
    element.attr('data-validation', false)

    element.closest('form').find('[data-form-submit]').removeClass('is-active')
  }
  function hideError (element) {
    let errorBlocks = element.closest('div').find('[data-error]')
    if (errorBlocks.length) {
      errorBlocks.remove()
    }
    element.removeClass('is-error')
    element.attr('data-validation', true)
  }
  // End working with authorization
})(jQuery)